import Vue from "vue";
import App from "@/App.vue";
import { store } from "@/store";
import { router } from "@/router";
import ApiService from "@/common/services/api.service";

import "@/common/plugins/bootstrap-vue";
import vuetify from "@/common/plugins/vuetify";

ApiService.init();

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  vuetify,
  render: (h) => h(App),
});
