import ApiService from "@/common/services/api.service";

// action types
export const GET_VERSIONS = "getVersions";
export const GET_VERSION = "getVersion";
export const CREATE_VERSION = "createVersion";
export const MAKE_VERSION_ACTIVE = "activeVersion";
export const UPDATE_VERSION = "updateVersion";
export const DELETE_VERSION = "deleteVersion";

// getters types
export const GET_VERSION_ERRORS = "getVersionErrors";

// mutation types
export const SET_VERSIONS = "setVersions";
export const SET_VERSION_PAGINATION_PAGE = "setVersionPaginationPage";
export const SET_VERSION_PAGINATION_ROWS_PER_PAGE =
  "setVersionPaginationRowPerPage";
export const SET_VERSION_PAGINATION_TOTAL_ITEMS =
  "setVersionPaginationTotalItems";
export const SET_VERSION = "setVersion";
export const SET_VERSION_LOADING = "setVersionLoading";
export const UNSET_VERSION = "unsetVersion";
export const SET_VERSION_ERRORS = "setVersionErrors";
export const RESET_VERSION_ERRORS = "resetVersionErrors";
export const SET_VERSION_EMPTY = "setVersionEmpty";

const state = {
  versionLoading: false,
  versionErrors: [],
  versions: [],
  versionPagination: {
    page: null,
    rowsPerPage: null,
    totalItems: null,
  },
};

const actions = {
  [GET_VERSIONS]({ commit }, { page, itemsPerPage, platformId }) {
    commit(SET_VERSION_EMPTY);
    return new Promise((resolve, reject) => {
      let queryParams = `page=${page}&limit=${itemsPerPage}`;
      if (platformId != null) {
        queryParams = queryParams + `&platform-id=${platformId}`;
      }
      ApiService.get(`versions?${queryParams}`)
        .then((response) => {
          const versions = response.data.data;
          let meta = response.data.meta;

          commit(SET_VERSIONS, versions);
          commit(SET_VERSION_PAGINATION_PAGE, meta.current_page);
          commit(SET_VERSION_PAGINATION_ROWS_PER_PAGE, meta.per_page);
          commit(SET_VERSION_PAGINATION_TOTAL_ITEMS, meta.total);

          resolve(response);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            commit(SET_VERSION_ERRORS, response.data.errors);
          }

          reject(response);
        });
    });
  },
  [GET_VERSION](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`versions/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_VERSION_ERRORS, response.data.errors);
          }

          reject(response);
        });
    });
  },
  [CREATE_VERSION](context, { payload }) {
    return new Promise((resolve, reject) => {
      ApiService.post("versions", payload)
        .then((response) => {
          context.commit(RESET_VERSION_ERRORS);
          resolve(response);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [UPDATE_VERSION](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(`versions/${data.id}`, data.payload)
        .then((response) => {
          context.commit(RESET_VERSION_ERRORS);
          resolve(response);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [MAKE_VERSION_ACTIVE](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.update(`versions/${id}/active`)
        .then((response) => {
          context.commit(RESET_VERSION_ERRORS);
          resolve(response);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_VERSION](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.deleteUrlWithSlug("versions", slug)
        .then((response) => {
          context.commit(UNSET_VERSION, slug);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(SET_VERSION_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_VERSIONS](state, versions) {
    state.versions = versions;
  },
  [SET_VERSION](state, version) {
    let foundIndex = state.versions.findIndex((x) => x.id === version.id);
    if (foundIndex >= 0) {
      state.versions.splice(foundIndex, 1, version);
    } else {
      state.versions.push(version);
    }
  },
  [UNSET_VERSION](state, index) {
    let foundIndex = state.versions.findIndex((x) => x.id === index);
    state.versions.splice(foundIndex, 1);
  },
  [SET_VERSION_ERRORS](state, errors) {
    state.versionErrors = typeof errors !== "undefined" ? errors : [];
  },
  [RESET_VERSION_ERRORS](state) {
    state.versionErrors = [];
  },
  [SET_VERSION_PAGINATION_PAGE](state, page) {
    state.versionPagination.page = page;
  },
  [SET_VERSION_PAGINATION_ROWS_PER_PAGE](state, rowsPerPage) {
    state.versionPagination.rowsPerPage = rowsPerPage;
  },
  [SET_VERSION_PAGINATION_TOTAL_ITEMS](state, totalItems) {
    state.versionPagination.totalItems = totalItems;
  },
  [SET_VERSION_LOADING](state, value) {
    state.versionLoading = value;
  },
  [SET_VERSION_EMPTY](state) {
    state.versions = [];
  },
};

export const versionsStore = {
  state,
  actions,
  mutations,
};
