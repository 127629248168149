import Vue from "vue";
import BootstrapVue from "bootstrap-vue";

// Include bootstrap vue
Vue.use(BootstrapVue);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default BootstrapVue;
