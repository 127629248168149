import Vue from "vue";
import Vuex from "vuex";

import { authStore } from "@/store/auth.module";
import { usersStore } from "@/store/users.module";
import { versionsStore } from "@/store/versions.module";
import { optionsStore } from "@/store/options.module";

Vue.use(Vuex);

const state = Object.assign(
  {},
  versionsStore.state,
  usersStore.state,
  authStore.state,
  optionsStore.state
);

const mutations = Object.assign(
  {},
  versionsStore.mutations,
  usersStore.mutations,
  authStore.mutations,
  optionsStore.mutations
);

const getters = Object.assign({}, authStore.getters);

const actions = Object.assign(
  {},
  versionsStore.actions,
  usersStore.actions,
  authStore.actions,
  optionsStore.actions
);

export const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
