import { router } from "@/router";
import ApiService from "@/common/services/api.service";
import JwtService from "@/common/services/jwt.service";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const CLEAR_STATE = "clearState";

// mutation types
export const SET_AUTH = "loginSuccess";
export const LOGIN_FAILURE = "loginFailure";
export const RESET_STATE = "resetState";

const state = {
  authErrors: null,
  user: JwtService.getUser(),
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then((response) => {
          const data = response.data.data;

          if (data.token) {
            context.commit(SET_AUTH, data.user);
            JwtService.saveTokens(data).then(() => {
              resolve(data);
            });
          }
        })
        .catch(({ response }) => {
          context.commit(LOGIN_FAILURE, response.data.errors);
          reject(response);
        });
    });
  },
  [LOGOUT]({ commit }) {
    commit(RESET_STATE);
    JwtService.destroyTokens();

    router.push({ name: "login" }).catch(() => {});
  },
  [CLEAR_STATE]({ commit }) {
    commit(RESET_STATE);
  },
};

const mutations = {
  [RESET_STATE](state) {
    state.isAuthenticated = false;
    state.user = null;
    state.authErrors = [];
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.authErrors = [];
  },
  [LOGIN_FAILURE](state, errors) {
    state.isAuthenticated = true;
    state.user = null;
    state.authErrors = errors;
  },
};

export const authStore = {
  getters,
  state,
  actions,
  mutations,
};
