import ApiService from "@/common/services/api.service";

// action types
export const GET_PLATFORMS = "getPlatforms";

// mutation types
export const SET_PLATFORMS = "setPlatforms";
export const SET_PLATFORM = "setPlatform";

const state = {
  platforms: [],
};

const actions = {
  [GET_PLATFORMS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("options/platforms", payload)
        .then((response) => {
          const platforms = response.data.data;
          context.commit(SET_PLATFORMS, platforms);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_PLATFORMS, response.data.errors);
          }

          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_PLATFORMS](state, platforms) {
    state.platforms = platforms;
  },
  [SET_PLATFORM](state, platform) {
    let foundIndex = state.platforms.findIndex((x) => x.id === platform.id);
    if (foundIndex >= 0) {
      state.platforms.splice(foundIndex, 1, platform);
    } else {
      state.platforms.push(platform);
    }
  },
};

export const optionsStore = {
  state,
  actions,
  mutations,
};
